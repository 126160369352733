import request from '@/utils/request'

import qs from 'qs'

const api_name = '/admin/process'

export default {
  findProcessType() {
    return request({
      url: `${api_name}/findProcessType`,
      method: 'get',
    })
  },

  getProcessTemplate(processTemplateId) {
    return request({
      url: `${api_name}/getProcessTemplate/` + processTemplateId,
      method: 'get',
    })
  },

  startUp(processFormVo) {
    return request({
      url: `${api_name}/startUp`,
      method: 'post',
      data: processFormVo,
    })
  },

  findPending(page, limit) {
    return request({
      url: `${api_name}/findPending/` + page + `/` + limit,
      method: 'get',
    })
  },
  // 查看详情
  show(id) {
    return request({
      url: `${api_name}/show/` + id,
      method: 'get',
    })
  },
  // 进行审批
  approve(approvalVo) {
    return request({
      url: `${api_name}/approve`,
      method: 'post',
      data: approvalVo,
    })
  },

  // 回退
  rewind(approvalVo) {
    return request({
      url: `${api_name}/rewind`,
      method: 'post',
      data: approvalVo,
    })
  },

  // 修改主送人
  modifySubsequentApprove(approvalVo) {
    return request({
      url: `${api_name}/modifySubsequentApprove`,
      method: 'post',
      data: approvalVo,
    })
  },

  // 审批人上传文件
  uploadFileByRecord(approvalVo) {
    return request({
      url: `${api_name}/uploadFileByRecord`,
      method: 'post',
      data: approvalVo,
    })
  },

  findProcessed(page, limit) {
    return request({
      url: `${api_name}/findProcessed/` + page + `/` + limit,
      method: 'get',
    })
  },

  findStarted(page, limit) {
    return request({
      url: `${api_name}/findStarted/` + page + `/` + limit,
      method: 'get',
    })
  },
  carbonCopyList(page, limit) {
    return request({
      url: `${api_name}/carbonCopyList/` + page + `/` + limit,
      method: 'get',
    })
  },

  // 获取所有部门
  getDepartmentList() {
    return request({
      url: `/admin/system/sysDept/list`,
      method: 'get',
    })
  },
  // 根据部门列表获取用户列表
  selectedDepartments(deptIds) {
    return request({
      url: `/admin/system/sysUser/getListByDeptIds`,
      method: 'get',
      params: {
        deptIds: deptIds, // 将部门 ID 列表以数组形式传递
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      },
    })
  },
  // 根据id删除文件
  deleteFileById(id) {
    return request({
      url: `/admin/upload/` + id,
      method: 'delete',
    })
  },
}
